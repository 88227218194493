<template>
  <div>
    <div
      v-permission="'appeals.create'"
      class="flex flex-wrap-reverse items-center">
      <div
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew">
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{ $t('AddNew') }}</span>
      </div>
    </div>
    <Grid
      :service="service"
      route_name="appeals"
      order_column="created_at">
      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="protocol_number" sort-key="protocol_number">{{ $t('appeal.protocol') }}</vs-th>
        <vs-th key="model_name" sort-key="model_name">{{ $t('appeal.questionnaire') }}</vs-th>
        <vs-th key="created_at" sort-key="created_at">{{ $t('appeal.created_at') }}</vs-th>
        <vs-th key="status" sort-key="status">{{ $t('appeal.status') }}</vs-th>
      </template>
      <template v-slot:gridData="props">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="id">
            <div> {{ tr.id }} </div>
          </vs-td>
          <vs-td width="20%" key="protocol_number">
            <span> {{ tr.protocol_number }}</span>
          </vs-td>
          <vs-td width="30%" key="model_name">
            <span> {{ tr.model_name }}</span>
          </vs-td>
          <vs-td width="10%" key="created_at">
            <span> {{ tr.created_dt_fmt }}</span>
          </vs-td>
          <vs-td width="10%" key="status" so>
            <span> {{ tr.handling_status.label }}</span>
          </vs-td>
          <vs-td width="10%">
            <span class="flex flex-row justify-center gap-1">
              <feather-icon
                v-permission="'appeal_handlings.show'"
                icon="EyeIcon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-success"
                @click="detail(props.data[indextr].id)"
                v-tooltip.top="{content: 'Detalhar Recurso',delay: { show: 1000, hide: 100 }}"/>
              <feather-icon v-if="props.data[indextr].handling_status.key == 'OP' || props.data[indextr].handling_status.key == 'PE'"
                v-permission="'appeals.edit'"
                icon="EditIcon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                @click="props.edit(props.data[indextr].id)"/>
              <feather-icon
                v-permission="'appeals.delete'"
                icon="Trash2Icon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
                @click="props.destroy(props.data[indextr])"/>
            </span>
          </vs-td>
        </vs-tr>
      </template>
    </Grid>
  </div>
</template>

<script>
import AppealService from '@/services/api/AppealService'

export default {
  data: () => ({
    selected: [],
    service: null
  }),
  methods: {
    addNew() {
      this.$router.push('/appeals/create')
    },
    detail(id) {
      this.$router.push(`/appeals/${id}/detail`)
    },
  },
  beforeMount() {
    this.service = AppealService.build(this.$vs)
  }
}
</script>
