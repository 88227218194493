import Rest from '../Rest'

export default class AppealService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/appeals'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  listHandlingHistory(id: Number) {
    return this.get(`${id}/handlings/historys`).then(data => {
      return data
    })
  }

}
